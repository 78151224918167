import React from "react";
import {
  Typography,
  Container,
  Box,
  Button,
  createTheme,
  ThemeProvider,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { red, green } from "@mui/material/colors";
import Logo from "./mainlogo.png";
import AboutUs from "./about us.svg";
import Forms from "./forms.svg";
import Transaction from "./transaction.svg";
import { motion } from "framer-motion";

const theme = createTheme({
  palette: {
    primary: {
      main: red[500], // Use the shade of red from your logo
    },
    secondary: {
      main: green[500], // Use the shade of green from your logo
    },
    text: {
      primary: "#fff", // Text color for primary elements
    },
    background: {
      default: "rgba(0, 0, 0, 0.8)", // Background color
    },
  },
});

const cardVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0 },
};

const welcomeVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};

const logoVariants = {
  hidden: { scale: 0.5, opacity: 0 },
  visible: { scale: 1, opacity: 1 },
};

const MainPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          minHeight: "100vh",
          color: "white",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Main Content */}
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Container
            maxWidth="lg"
            sx={{ textAlign: "center", margin: "20px auto" }}
          >
            <motion.div
              variants={welcomeVariants}
              initial="hidden"
              whileInView="visible"
              transition={{ duration: 1.3 }}
              viewport={{ once: true }}
              style={{ marginBottom: "20px" }}
            >
              <Typography
                variant="h4"
                component="h1"
                sx={{
                  fontWeight: "bolder",
                  color: "#008080", // Teal color
                  fontSize: {
                    xs: "24px", // Extra-small devices
                    sm: "28px", // Small devices
                    md: "32px", // Medium devices
                    lg: "40px", // Large devices
                    xl: "50px", // Extra-large devices
                  },
                }}
              >
                Welcome
              </Typography>
              <Typography
                variant="h4"
                component="h1"
                sx={{
                  fontWeight: "bolder",
                  color: "#008080", // Teal color
                  fontSize: {
                    xs: "24px", // Extra-small devices
                    sm: "28px", // Small devices
                    md: "32px", // Medium devices
                    lg: "40px", // Large devices
                    xl: "50px", // Extra-large devices
                  },
                }}
              >
                to
              </Typography>
            </motion.div>
            <motion.div
              variants={logoVariants}
              initial="hidden"
              whileInView="visible"
              transition={{ duration: 1.5 }}
              viewport={{ once: true }}
            >
              <img
                src={Logo}
                alt="Logo"
                style={{
                  height: "auto",
                  margin: "0 auto",
                  width: "auto",
                  objectFit: "contain",
                  display: "block",
                  maxWidth: "600px",
                }}
              />
            </motion.div>
            <Box
              sx={{
                mt: 4,
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                justifyContent: "center",
              }}
            >
              {/* About TMB Card */}
              <motion.div
                variants={cardVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 1 }}
              >
                <Card
                  sx={{
                    width: { xs: 200, lg: 280 },
                    height: { xs: 150, sm: 180, md: 220, lg: 280 },
                  }}
                >
                  <CardMedia
                    component="img"
                    height="60%"
                    image={AboutUs}
                    alt="About TMB"
                  />
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "40%",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      target="_blank"
                      href="https://home.tmbonline.ng"
                      sx={{
                        mt: 1,
                        padding: {
                          xs: "2px 4px",
                          sm: "3px 6px",
                          md: "4px 8px",
                          lg: "5px 10px",
                        },
                        fontSize: {
                          xs: "0.75rem", // Extra small screens
                          sm: "0.875rem", // Small screens
                          md: "1rem", // Medium screens
                          lg: "1.125rem", // Large screens
                        },
                      }}
                    >
                      About Us
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>

              {/* Complete a Form Card */}
              <motion.div
                variants={cardVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 1.7 }}
              >
                <Card
                  sx={{
                    width: { xs: 200, lg: 280 },
                    height: { xs: 150, sm: 180, md: 220, lg: 280 },
                  }}
                >
                  <CardMedia
                    component="img"
                    height="60%"
                    image={Forms}
                    alt="Complete a Form"
                  />
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "40%",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      target="_blank"
                      href="https://forms.tmbonline.ng/"
                      sx={{
                        mt: 1,
                        color: "white",
                        padding: {
                          xs: "2px 4px",
                          sm: "3px 6px",
                          md: "4px 8px",
                          lg: "5px 10px",
                        },
                        fontSize: {
                          xs: "0.75rem", // Extra small screens
                          sm: "0.875rem", // Small screens
                          md: "1rem", // Medium screens
                          lg: "1.125rem", // Large screens
                        },
                      }}
                    >
                      Complete a Form
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>

              {/* Make a Transaction Card */}
              <motion.div
                variants={cardVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 2 }}
              >
                <Card
                  sx={{
                    width: { xs: 200, lg: 280 },
                    height: { xs: 150, sm: 180, md: 220, lg: 280 },
                  }}
                >
                  <CardMedia
                    component="img"
                    height="60%"
                    image={Transaction}
                    alt="Make a Transaction"
                  />
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "40%",
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "black", color: "white" }}
                      target="_blank"
                      href="https://transactions.tmbonline.ng/"
                      sx={{
                        mt: 1,
                        padding: {
                          xs: "2px 4px",
                          sm: "3px 6px",
                          md: "4px 8px",
                          lg: "5px 10px",
                        },
                        fontSize: {
                          xs: "0.75rem", // Extra small screens
                          sm: "0.875rem", // Small screens
                          md: "1rem", // Medium screens
                          lg: "1.125rem", // Large screens
                        },
                      }}
                    >
                      Make a Transaction
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>
            </Box>
          </Container>
        </Box>

        {/* Footer */}
        <Box
          sx={{
            py: 2,
            backgroundColor: "#008080",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Typography variant="body2" color="inherit">
            &copy; {new Date().getFullYear()} Townserve Microfinance Bank
            Nigeria Limited. All rights reserved
          </Typography>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default MainPage;
